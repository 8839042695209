const urls = {
  'local':'http://localhost:3334',
  'prod':'https://yh6mpavvnhoo9mv75znnlyqml9ky.matthewspeiser.us'
}

export default function geturl(env){
  switch(env){
    case 'local':
      return urls['local']
    case 'prod':
      return urls['prod']
    default:
      return urls['local']
  }
}
