import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ActionAreaCard(props) {
  let title = props.title ? props.title : "Lizards";
  let image = props.image ? `${props.image}` : "/image.png";
  let description = props.description ? props.description : "";
  let publishedDate = props.publishedDate ? props.publishedDate : "";
  //console.log(props);
  //console.log(publishedDate);
  return (
    <Card sx={{ maxWidth: 345, padding:'1em 0',boxShadow:'1px 1px 8px 5px #d9d9d9' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="green iguana"
        />
        <CardContent>
          <Typography variant="body2" color="text.primary">
            {publishedDate}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
          {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
} 
