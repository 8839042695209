import * as React from 'react';
import { useState,useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import getjsx from '../utils/getjsx';
import geturl from '../utils/urls';
const baseUrl = geturl('prod');

function Project() {

  let [projectdetails,setProject] = useState({tile:"",content:[]});
  let { project } = useParams();
  useEffect(() => {
    fetch(`${baseUrl}/project/${project}`)
      .then(res => res.json())
      .then(data => {
        setProject(data?.projects[0]);
    });
  },[project]);

  return (
    <div className="About">
      <Navbar />
      <div className="Blog-Main">
        <h1 className="Blog-Title">{ projectdetails.title }</h1>
        {projectdetails && projectdetails.content.map(getjsx)}
      </div>
    <Footer />
    </div>
  )
}
export default Project;
