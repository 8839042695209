import { NavLink } from "react-router-dom";
import ActionAreaCard from '../components/NavCard';

export default function getjsx(data,i){
  //console.log(data)
  let innercontent = "";
  let listItems = [];
  if(typeof data === 'string'){
    return data;
  }
  switch(data.type){
    case "text-title":
      let titleclasses = "Blog-Title "
      data?.classes && (titleclasses += data.classes);
      return (<h2 key={i} className={titleclasses}>{data.text}</h2>)
    case "text-section-title":
      let titlesectionclasses = "Blog-Section-Title "
      data?.classes && (titlesectionclasses += data.classes);
      return (<h2 key={i} className={titlesectionclasses}>{data.text}</h2>)
    case "id-text-section-title":
      let idtitlesectionclasses = "Blog-Section-Title "
      data?.classes && (idtitlesectionclasses += data.classes);
      return (<h2 id={data.id} key={i} className={idtitlesectionclasses}>{data.text}</h2>)
    case "text-paragraph":
      let textclasses = "Blog-Text ";
      data?.classes && (textclasses += data.classes);
      return (<p key={i} className={textclasses}>{data.text}</p>)
    case "image":
      let imageclasses = "Blog-Image ";
      data?.classes && (imageclasses += data.classes);
      return (<img key={i} src={data.src} alt="" className={imageclasses} />)
    case "embedded":
      let classes = "Blog-Embedded ";
      data?.classes && (classes += data.classes);
       function createMarkup() {
         return {__html: data.iframe};
       }
      return (<div key={i} className={classes} dangerouslySetInnerHTML={createMarkup()} />)
    case "list-item":
      let liclasses = "Blog-List-Item";
      data?.classes && (liclasses += data.classes);
      innercontent = data.text;
      if(data.content){
        innercontent = data.content.map(getjsx)
      }
      return (<li key={i} className={liclasses}>{innercontent}</li>)
    case "unordered-list":
      let ulclasses = "Blog-List";
      data?.classes && (ulclasses += data.classes);
      listItems = data.listItems ? [...data.listItems] : [];
      return (<ul className={ulclasses}>{listItems.map(getjsx)}</ul>)
    case "ordered-list":
      let olclasses = "Blog-List";
      data?.classes && (olclasses += data.classes);
      listItems = data.listItems ? [...data.listItems] : [];
      return (<ol className={olclasses}>{listItems.map(getjsx)}</ol>)
    case "link":
      let aclasses = "Blog-Link"
      data?.classes && (aclasses += data.classes);
      let href = data.href ? data.href : "#";
      let text = data.text ? data.text : "#";
      return (<a className={aclasses} href={href}>{text}</a>)
    case "local-nav-link":
      let navlinkclasses = "Local-Link"
      data?.classes && (navlinkclasses += data.classes);
      let key = data.key ? data.key : "nav-link-0";
      let path = data.path ? data.path : "#";
      let innertext = data.innerText ? data.innerText : "";
      return (
        <NavLink
          className={navlinkclasses}
          key={`${key}`} 
          to={path}>
            {innertext}
        </NavLink>)
    case "local-link-text-embed":
      let linkclasses = "post-text"
      data?.classes && (linkclasses += data.classes);
      let key1 = data.key ? data.key : "nav-link-0";
      return (
        <h2
          className={linkclasses}
          key={`${key1}`}>
            {data.prefixText}
            {getjsx(data.link)}
            {data.suffixText}
        </h2>)
    case "blog-card":
      let bloglinkclasses = "post-blog-link"
      data?.classes && (bloglinkclasses += data.classes);
      let key2 = data.key ? data.key : "nav-link-1";
      let path2 = data.path ? data.path : "#";
      let blog = data.blog ? data.blog : { title: 'Default', description: 'default description', publishedDate: '12/07/2022', previewImage: '' };
      return (
        <NavLink
          className={bloglinkclasses}
          key={`${key2}`} 
          to={path2}>
            <ActionAreaCard 
              key={blog.title}
              title={blog.title} 
              description={blog.description}
              publishedDate={blog.publishedDate}
              image={blog.previewImage}
              sx={{ padding:'1em 0em' }}
            />
        </NavLink>)
    default:
      return (<p key={i} className="Blog-Text">Error no matching type</p>)
  }
}
