import * as React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
function Contact() {

  return (
    <div className="About">
      <Navbar />
      <h1>Contact</h1>
      <Footer />
    </div>
  )
}
export default Contact;
