import * as React from 'react';
import Navbar from './components/Navbar';
import { useState, useEffect } from 'react';
import './App.css';
import Button from '@mui/material/Button';
import mainImg from './IMG_5898.jpg';
import Footer from './components/Footer';
import AnimationContainer from './components/AnimationContainer';
import getjsx from './utils/getjsx';
import geturl from './utils/urls';

function App() {

  let [ showLoad, setShowLoad ] = useState(true);
  let [ feed, setFeed ] = useState([]);
  let [ weather, setWeather ] = useState([{temp:0, feels_like:0}]);
  const baseUrl = geturl('prod');
  useEffect(() => {
    let setters = {
      feed:setFeed,
      weather:setWeather
    }
    let paths = ['feed','weather']
    paths.forEach(path => {
      fetch(`${baseUrl}/${path}`)
        .then(res => res.json())
        .then(data => {
          setters[path](data[path]);
          if(!data[path] || data[path].length === 0){
            setShowLoad(false);
          }
      });
      
    });
  },[baseUrl]);

  let loadFeed = () => {
    let lastId = feed[feed.length - 1]._id;
    fetch(`${baseUrl}/feed/${lastId}`)
      .then(res => res.json())
      .then(data => {
        const oldLength = feed.length;
        const updatedFeed = [...feed, ...data.feed];
        const newLength = updatedFeed.length;
        setFeed(updatedFeed);
        if(newLength === oldLength){
          setShowLoad(false);
        }
    });
  }

  let getcontent = (content,i) => {

    if(content?.length && typeof content === "string"){
      return (<h2 key={i} className="post-text">{content}</h2>)
    }

    if(typeof content === "object"){
      return getjsx(content,i);
    }
  }

  let post = (date="none",content=["None"]) => (
    <div key={date} className="post">
      <p className="text-secondary align-right sm-margin-top">{date}</p>
      {content.map(getcontent)}
    </div>
  )

  let jsxposts = (p) => {
    return post(p.postDate, p.content)
  }

  return (
    <div className="App">
      <Navbar />
      <div className="App-main">
        <div className="main-left">
          <img src={mainImg} className="width100" alt="logo" />
        </div>
        <AnimationContainer 
          temperature={weather[0].temp} 
          feels_like={weather[0].feels_like} 
        />
      </div>
      <div id="feed">
        {feed && feed.map(jsxposts)}
        {showLoad === true && <Button variant='outlined' onClick={loadFeed}>Load More</Button>}
      </div>
      <Footer />
    </div>
  );
}

export default App;
