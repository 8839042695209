import * as React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
//import StraightIcon from '@mui/icons-material/Straight';
//import Button from '@mui/material/Button';
import '../App.css';
function About() {

  //function topClick(e){
  //  e.preventDefault()
  //  let feed = document.getElementById("table-of-contents");
  //  //let opts = {behavior:'smooth'}
  //  feed.scrollIntoView({});
  //}
  return (
    <div className="About">
      <Navbar />
      <div className="About-Main">
        <h1 className="About-Title">About</h1>
        <div className="About-Image-Wrap">
          <img src="/aboutimage.jpg" className="About-Image" alt="logo" />
        </div>
        <div className="About-Content">
          <p className="About-Text">Hi, my name is Matthew Speiser. Thanks for visiting my site!</p>
          <div id="table-of-contents">
            <h3 className="About-Font margin0">Table Of Contents</h3>
            <ul className="table-of-contents-list">
              <li className="table-of-contents-item"><a className="table-of-contents-link" href="#title-statement">My Statement</a></li>
              <li className="table-of-contents-item"><a className="table-of-contents-link" href="#about-me">About Me</a></li>
              <li className="table-of-contents-item"><a className="table-of-contents-link" href="#my-coding-journey">My Coding Journey</a></li>
              <li className="table-of-contents-item"><a className="table-of-contents-link" href="#my-experience">Work Experience</a></li>
            </ul>
          </div>
          <div>
            <h4 id="title-statement" className="About-Text">My Statement</h4>
            <p className="About-Text">The world is undergoing a rapid expansion of technology, and this presents an exciting opportunity for people in the industry to solve problems and improve people's lives. I believe that the community of engineers and developers today has more potential than ever before to help potentially billions of people transform their everyday experiences. I believe that by applying technology in experimental and ambitious ways, we can discover new ideas and innovations that could lead to more effective solutions to our problems.</p>
            <p className="About-Text">My goal is to build innovative software that makes a difference in people's lives. I strive to be recognized as dependable, innovative, and a great programmer who is enjoyable to work with.</p>
            <p className="About-Text">I am passionate about learning and always looking to improve my knowledge and skills in software engineering, problem-solving, communication, and using technology to build the best solutions. I recognize that I am standing on the shoulders of giants in the field, and I aim to be someone of value in the software engineering community.</p>
            <p className="About-Text">Please check back for regular updates, or contact me if you would like to work together on a project. I look forward to collaborating and making a positive impact in the world through software engineering.</p>
          </div>
          <div>
            <h4 id="about-me" className="About-Text">About Me</h4>
            <p className="About-Text">Originally from Santa Monica, California, I've faced and overcome numerous obstacles throughout my life, most notably being diagnosed with Tourette Syndrome at the age of 10. Throughout my childhood and teenage years, I struggled to find the right balance between the drug benefits and side effects of various medications used to manage my symptoms. Fortunately, Tourettes is known to be a disorder that can subside into your late 20s, and I was fortunate enough to be one of those people.</p>
            <p className="About-Text">While I attempted to pursue a college degree, I found that the traditional classroom environment wasn't conducive to my learning style. Instead, I worked various jobs, including waiting tables, retail sales, coaching soccer, DJing, hosting events, renting tuxedos/suits, and sales. However, it wasn't until I discovered programming that I found my true passion.</p>
            <p className="About-Text">As a non-traditional learner, I found that an autodidactic style of learning suits me. I read books, take online courses, watched videos, try new technology, and practice what I learn. Over time I developed a large range of knowledge and skills. Now, coding, programming, and executing ideas is a lifelong journey for me.</p>
            <p className="About-Text">Through this blog and site, I aim to showcase my work, curate content that I enjoy, and provide useful information to those who want to learn more about me.</p>
          </div>
          <div>
            <h4 id="my-coding-journey" className="About-Text">My Coding Journey</h4>
          </div>
          <div>
            <h4 id="my-experience" className="About-Text">Work Experience</h4>
            <p className="About-Text">As a senior software engineer with experience dating back to 2015, I have worked on a wide range of projects involving websites, web services, APIs, databases, and devops.</p>
          </div>
        </div>
      </div>
      <Footer />
      </div>
      //<div className="floating-to-top">
      //<Button 
      //  variant="default"
      //  size="medium" 
      //  sx={{
      //    width: 50,
      //    height: 50,
      //    padding: 0,
      //    borderRadius: 0,
      //    border: "none"
      //  }}
      //  onClick={topClick}>
      //  <StraightIcon 
      //    fontSize="large" 
      //    sx={{
      //      width: 50,
      //      height: 50,
      //      padding: 0,
      //      borderRadius: 0,
      //      border: "1px solid",
      //      borderColor: "primary.main",
      //      color: "primary.main"
      //    }}/>
      //</Button>
      //</div>
  )
}
export default About;
