import * as React from 'react'
import '../App.css';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";
//import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
//import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';

let TemporaryDrawer = () => {

  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [openblogs, setOpenBlogs] = React.useState(false);
  const [openprojects, setOpenProjects] = React.useState(false);
  const [opencontact, setOpenContacts] = React.useState(false);

  const openBlogsMenu = () => {
    setOpenBlogs(!openblogs);
  };
  const openProjectsMenu = () => {
    setOpenProjects(!openprojects);
  };
  const openContactsMenu = () => {
    setOpenContacts(!opencontact);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItemButton onClick={() => navigate("/")}>
          <ListItemText primary="Feed" />
        </ListItemButton>
        <ListItemButton>
          <ListItemText primary="About" onClick={() => navigate("/about",{replace:true})} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={openBlogsMenu}>
          <ListItemText primary="Blogs" />
            {openblogs ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openblogs} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/blogs/programming",{replace:true})}>
              <ListItemText primary="Programming" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/blogs/personal",{replace:true})}>
              <ListItemText primary="Personal" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/blogs/travel",{replace:true})}>
              <ListItemText primary="Travel" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/blogs/content",{replace:true})}>
              <ListItemText primary="Content" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={openProjectsMenu}>
          <ListItemText primary="Projects" />
            {openprojects ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openprojects} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/projects/backchannel",{replace:true})}>
              <ListItemText primary="Backchannel" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/projects/codingtimers",{replace:true})}>
              <ListItemText primary="Time Tracking" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={openContactsMenu}>
          <ListItemText primary="Contact" />
            {opencontact ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={opencontact} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/contact/message",{replace:true})}>
              <ListItemText primary="Message" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/contact/appointment",{replace:true})}>
              <ListItemText primary="Appointment" />
            </ListItemButton>
          </List>
        </Collapse>

      </List>
    </Box>
  );

  return (
    <div className="mobileShow">
        <React.Fragment key={'top'}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer('top', true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor='top'
            open={state['top']}
            onClose={toggleDrawer('top', false)}
          >
            {list('top')}
          </Drawer>
        </React.Fragment>
    </div>
  );
}

export default TemporaryDrawer;
