import * as React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

function Message() {

  return (
    <div className="None">
      <Navbar />
      <div className="Contact-Message-Main"> 
        <h1 className="Contact-Message-Title">Contact Message</h1>
        <p className="Contact-Message-Text">Have a question, want to share some feedback, or want to chat about something specific? Send me a contact message and I will get back to you as soon as possible!</p>
        <ContactForm />
      </div>
      <Footer />
    </div>
  )
}
export default Message;
