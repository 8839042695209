import * as React from 'react'
import { useState } from 'react';
import '../App.css';
import { 
  timeout,
  getElements,
  frameTimeout
} from '../utils/animation_helpers.js';

document.animationContainer = {
  generator: null,
  running: false,
  stopped: false,
  currentStep: null,
  animationStep:null,
  stepIndex: 0,
  hideOnRestart:null,
  animationOrder:[
    {
      elementIds: [],
      animation: '',
      wait: 400
    },
    {
      elementIds: [],
      animation: '',
      wait: 400
    },
    {
      elementIds: ['text1','text2','text3'],
      animation: 'animation1',
      wait: 3000,
      groupId:1
    },
    {
      elementIds: ['text1','text2','text3'],
      animation: 'animation4',
      wait: 1500,
      groupId:1
    },
    {
      elementIds: ['text4','text5','text6'],
      animation: 'animation1',
      wait: 5000,
      groupId:2
    },
    {
      elementIds: ['text4','text5','text6'],
      animation: 'animation4',
      wait: 1500,
      groupId:2
    }
  ],
  runningAnimationStep: false,
  start: function(){
    this.running = true
    if (!this.runningAnimationStep && this.running){
      this.runAnimationStep(); 
    }
  },
  stop: function(){
    this.running = false;
    this.stopped = true;
  },
  restart: async function(){
    this.restarted = true;
  },
  runAnimation: function*(){
    let i = 0;
    while(true){
      if(i > this.animationOrder.length-1){
        i = 0;
      }
      yield i++
    }
  },
  initialized: false,
  init: function() {
    this.initialized = true;
    this.generator = this.runAnimation(this.stepIndex);
  },
  runAnimationStep: async function(){
    this.runningAnimationStep = true;
    let i = this.generator.next().value
    this.animationStep = this.animationOrder[i];
    if (!this.animationStep) {
      return;
    }

    let previousStep = null;
    if(i > 0){
      previousStep = this.animationOrder[i-1];
    }

    if(this.restarted === true){
      this.restarted = false;
    }

    const els = getElements(...this.animationStep.elementIds);
    
    await timeout(10, () => { els.forEach(el => el.classList.remove('hidden')) });
    await timeout(50, () => { els.forEach(el => el.classList.remove(this.animationStep.animation)) });

    els.reduce((acc, el, i) => {
      timeout((i*200)+acc, () => { el.classList.add(this.animationStep.animation)});
      return (i*200)+acc;
    }, 100);
    
    await frameTimeout(this.animationStep.wait);

    if (i > 0 && i % 2 !== 0) {
      let removeAnimations = [this.animationStep.animation]
      if (previousStep){
        removeAnimations.push(previousStep.animation);
      }
      await timeout(10, () => { els.forEach(el => el.classList.add('hidden')) });
      await timeout(10, () => { els.forEach(el => el.classList.remove(...removeAnimations)) });
    }

    this.hideOnRestart = {...this.animationStep}; 
    this.runningAnimationStep = false;
    if (this.running){
      this.start();
    }
  }
}

if(!document.animationContainer.initialized){
  document.animationContainer.init();
}


const Frame1 = () => {
  let [ currentClasses ] = useState('title invisible hidden animationItem');
  let [ currentClasses1 ] = useState('title invisible hidden animationItem');
  let [ currentClasses2 ] = useState('title invisible hidden animationItem');
  return (
    <>
    <h2 id="text1" className={currentClasses}>Hi, I'm Matthew Speiser</h2>
    <h2 id="text2" className={currentClasses1}>Software Engineer</h2>
    <h2 id="text3" className={currentClasses2}>Welcome To My Personal Site</h2>
    </>
  )
}

const Frame2 = (temp, feels) => {
  let [ currentClasses ] = useState('title invisible hidden animationItem');
  let [ currentClasses1 ] = useState('title invisible hidden animationItem');
  let [ currentClasses2 ] = useState('title invisible hidden animationItem');
  return (
    <>
    <h2 id="text4" className={currentClasses}>The current temperature in Denver is:</h2>
    <h2 id="text5" className={currentClasses1}><span className="degrees">{Math.round(temp)}&deg;F</span> <span className="degrees">{Math.round((Math.round(temp)-32)*5/9)}&deg;C</span></h2>
    <h2 id="text6" className={currentClasses2}>Feels like {Math.round(feels)}	&deg;F</h2>
    </>
  )
}
const AnimationContainer = (props) => {
  if(!document.animationContainer.running){
    document.animationContainer.start();
  }
  return (
    <div id="main-right" className="main-right">
      {Frame1()}
      {Frame2(props.temperature, props.feels_like)}
    </div>
  );
}



//const animationCarousel = async () => {
//  const p = new Promise(resolve => {
//    resolve({})
//  });
//  p.then(async (data) => {
//    if(checkContainerDisplay()){
//    await triggerFrame1();
//    return await exitFrame1();
//    }
//  }).then(async (data) => {
//    if(checkContainerDisplay()){
//    await triggerFrame2();
//    return await exitFrame2();
//    }
//  })
//  .then(async (data) => {
//    if(checkContainerDisplay()){
//      timeout(2100, animationCarousel);
//    }
//  });
//}

 
export default AnimationContainer;
