import * as React from 'react';
//import { useState } from 'react';
import Navbar from '../components/Navbar';
import ActionAreaCard from '../components/NavCard';
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer';
function Projects() {
  let navigate = useNavigate();
  //let [projects,setProjects] = useState(null);
  //useState(() => {
  //  setProjects([])
  //},[])
  return (
    <div className="">
      <Navbar />
      <div className="Blogs-Main">
        <h1 className="Blogs-Title">Projects</h1>
        <div 
            onClick={() => navigate("/projects/backchannel")}
            className="Blog-Cards">
          <ActionAreaCard 
            title="Backchannel" 
            image=""/>
        </div>

        <div 
          onClick={() => navigate("/projects/codingtimers")}
          className="Blog-Cards">
          <ActionAreaCard 
            title="Coding Timers" />
        </div>

        <div 
          onClick={() => navigate("/projects/gocli")}
          className="Blog-Cards">
          <ActionAreaCard 
            title="Go CLI" 
            image=""/>
        </div>

        <div 
          onClick={() => navigate("/projects/alphafiler")}
          className="Blog-Cards">
          <ActionAreaCard 
            title="Alphafiler"/>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Projects;
