import * as React from 'react';
//import { useState } from 'react';
import Navbar from '../components/Navbar';
import ActionAreaCard from '../components/NavCard';
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer';
function Blogs() {
  let navigate = useNavigate();
  //let [blogcategories,setBlogCategories] = useState(null);
  //useState(() => {
  //  setBlogCategories([])
  //},[])
  return (
    <div className="">
      <Navbar />
      <div className="Blogs-Main">
        <h1 className="Blogs-Title">Blogs</h1>
        <div 
          onClick={() => navigate("/blogs/programming")}
          className="Blog-Cards">
          <ActionAreaCard 
            title="Programming" 
            image=""/>
        </div>

        <div 
          onClick={() => navigate("/blogs/personal")}
          className="Blog-Cards">
          <ActionAreaCard 
            title="Personal" />
        </div>

        <div 
          onClick={() => navigate("/blogs/travel")}
          className="Blog-Cards">
          <ActionAreaCard 
            title="Travel" 
            image="travelpic.jpg"/>
        </div>

        <div 
          onClick={() => navigate("/blogs/content")}
          className="Blog-Cards">
          <ActionAreaCard title="Content"/>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Blogs;
