import * as React from 'react'
import '../App.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import TemporaryDrawer from './Drawer';
import {
  NavLink
} from "react-router-dom";

let BasicMenu = (menuname="Dashboard",items=[]) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavLinkClick = (str) => {
    document.animationContainer.stop();
  }

  const menuitems = (titleobj,i) => {
    let path = titleobj.path ? `/${menuname.toLowerCase()}/${titleobj.path.toLowerCase()}` : `/${menuname.toLowerCase()}/${titleobj.title.toLowerCase()}`;
    return (<NavLink
              key={`${titleobj.title}${i}`}
              onClick={handleNavLinkClick}
              to={path} >
                <MenuItem key={i} 
                  onClick={handleClose}>{titleobj.title}
                </MenuItem></NavLink>)
  }
  let allmenuitems = items.map((t,i) => menuitems(t,i))

  return (
    <div 
    >
      <Button
        id={menuname}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{color: '#FFFFFF',fontSize:'1.3em',marginRight:'1em'}}
        onClick={handleClick}
      >
      {menuname}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {allmenuitems}
      </Menu>
    </div>
  );
}

let Navbar = () => {
  let navigate = useNavigate();

  function feedClick(e){
    e.preventDefault()
    let feed = document.getElementById("feed");
    if(feed !== null){
      let opts = {behavior:'smooth'}
      feed.scrollIntoView(opts);
    } else {
      navigate("/");
    }
  }

  function navigateTo(event) {
    event.preventDefault();
    document.animationContainer.stop();
    navigate("/about");
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
         <TemporaryDrawer />
         <div className="mobileHide">
           <Button onClick={feedClick} style={{color:'#FFFFFF',fontSize:'1.3em',marginRight:'1em'}}>Feed</Button>
         </div>
         <div className="mobileHide">
           <Button className="mobileHide" onClick={navigateTo} style={{color:'#FFFFFF',fontSize:'1.3em',marginRight:'1em'}}>About</Button>
         </div>
         <div className="mobileHide">
         {BasicMenu("Blogs",[{title:"Programming"},{title:"Travel"},{title:"Personal"},{title:"Content"}])}
         </div>
         <div className="mobileHide">
         {BasicMenu("Projects",[{title:"Backchannel",path:"backchannel"},{title:"Coding Timers",path:"codingtimers"}])}
         </div>
         <div className="mobileHide">
         {BasicMenu("Contact",[{title:"Message"},{title:"Appointment"}])}
         </div>
         <div></div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

 
export default Navbar;
