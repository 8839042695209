
const timeout = async (ms, fn) => {
  if(checkContainerDisplay()){
  return await new Promise((resolve) => {
    setTimeout(() => {
      try {
        if(checkContainerDisplay()){
          fn();
        }
      } catch(e){

      }
      resolve(ms)
    }, ms);
  });
  }
}

const frameTimeout = async (ms) => {
  return await new Promise(resolve => {
    setTimeout(resolve, ms);  
  });
}

const getElements = (...ids) => {
  const textids = [...ids];
  return textids.map(id => document.getElementById(id))
}

const checkContainerDisplay = () => {
  if(document.getElementById('main-right') === null){
    return false;
  }
  return true;
}

export { 
  timeout,
  checkContainerDisplay,
  getElements,
  frameTimeout
}
