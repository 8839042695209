import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useNavigate } from "react-router-dom";
import getjsx from '../utils/getjsx';
import geturl from '../utils/urls';
const baseUrl = geturl('prod');

function Blog() {
  let navigate = useNavigate();
  let params = useParams();
  let [blogdetails,setBlog] = useState({title:"",content:[]});
  let { blog } = params; 

  console.log(baseUrl);
  useEffect(() => {
    fetch(`${baseUrl}/blog/${blog}`)
      .then(res => res.json())
      .then(data => {
        setBlog(data.blogs[0]);
    });
  },[blog]);

  return (
    <div className="About">
      <Navbar />
      <div className="Blog-Main">
        <div className="Blog-Stack">
          <Stack direction="row" alignItems="center" justifyContent="" spacing={2} >
            <Button variant="contained" onClick={() => navigate(-1)}>Exit</Button>
          </Stack>
        </div>
        <h1 className="Blog-Title">{blogdetails.title}</h1>
        {blogdetails && blogdetails.content.map(getjsx)}
      </div>
    </div>
  )
}
export default Blog;
