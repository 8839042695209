import * as React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
function Appointment() {

  return (
    <div className="About">
      <Navbar />
      <div className="Contact-Message-Main"> 
        <h1 className="Contact-Message-Title">Appointment</h1>
        <p className="Contact-Message-Text">This feature is currently unavailable but will be implemented soon!</p>
      </div>
      <Footer />
    </div>
  )
}
export default Appointment;
