import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Navbar from '../components/Navbar';
import ActionAreaCard from '../components/NavCard';
import Footer from '../components/Footer';
import geturl from '../utils/urls';
const baseUrl = geturl('prod');

let upperCaseWord = (text) => {
  let s = text.split("")
  s[0] = s[0].toUpperCase();
  return s.join("");
}

let upperCaseWords = (text) => {
  let out = "";
  let s = text.split(" ");
  if(s.length > 1){
    for(const word of s){
      out = out + " " + upperCaseWord(word);
    }
    return out;
  }
  return upperCaseWord(text);
}

function BlogCategory() {
  let navigate = useNavigate();
  let { category } = useParams();
  let [ showLoad, setShowLoad ] = useState(true);
  let [ programmingblogs, setprogrammingblogs ] = useState([]);
  let [ contentblogs, setcontentblogs ] = useState([]);
  let [ travelblogs, settravelblogs ] = useState([]);
  let [ personalblogs, setpersonalblogs ] = useState([]);

  let getters= {
    'programming':programmingblogs,
    'content':contentblogs,
    'travel':travelblogs,
    'personal':personalblogs
  }
  let outersetters= {
    'programming':setprogrammingblogs,
    'content':setcontentblogs,
    'travel':settravelblogs,
    'personal':setpersonalblogs
  }

  let loadFeed = () => {
    let lastId = getters[category][getters[category].length - 1];
    if(!lastId){

      setShowLoad(false)
      return;
    }
    lastId = lastId._id;
    fetch(`${baseUrl}/blogs/${category}/${lastId}`)
      .then(res => res.json())
      .then(data => {
        const blogs = getters[category];
        const updatedBlogs = [...blogs, ...data.blogs]
        const oldLength = blogs.length;
        const newLength = updatedBlogs.length;
        outersetters[category](updatedBlogs);
        if(newLength === oldLength){
          setShowLoad(false);
        }
    });
  }

  useEffect(() => {
    let setters= {
      'programming':setprogrammingblogs,
      'content':setcontentblogs,
      'travel':settravelblogs,
      'personal':setpersonalblogs
    }

    let fetchblogs = async (category) => {
      return await fetch(`${baseUrl}/blogs/${category}`)
        .then(res => res.json())
        .then(data => {
          setters[category](data.blogs)
      });
    }

    Promise.all(Object.keys(setters).map(async (category) => {
      return await fetchblogs(category);
    }));

    setShowLoad(true);

    },[category])
  
  let allblogs = (blogs) => (blogs.map(x => (
    <div 
      key={x.title} 
      className="Blog-Post"
      onClick={() => navigate(`/blogs/${category.toLowerCase()}/${x._id}`)}
    >
      <ActionAreaCard 
        key={x.title}
        title={x.title} 
        description={x.description}
        publishedDate={x.publishedDate}
        image={x.previewImage}
        sx={{ padding:'1em 0em' }}
      />
    </div>
  )));

  return (
    <div className="About">
      <Navbar />
      <div className="Blog-Main">
        <h1 className="Blog-Title">{upperCaseWords(category)}</h1>
        {category === "programming" && allblogs(programmingblogs)}
        {category === "content" && allblogs(contentblogs)}
        {category === "travel" && allblogs(travelblogs)}
        {category === "personal" && allblogs(personalblogs)}
        {showLoad === true && <Button variant='outlined' onClick={loadFeed}>Load More</Button>}
      </div>
      <Footer />
    </div>
  )
}
export default BlogCategory;
