import * as React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function ErrorPage() {

  return (
    <div className="About">
      <Navbar />
      <h1>404 Page Not Found</h1>
      <Footer />
    </div>
  )
}
export default ErrorPage;
