import * as React from 'react'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GithubIcon from '@mui/icons-material/GitHub';
let Footer = () => {

  return (
	  <footer className="footer">
	 	  <div className="col-md-2 col-sm-6">
	  		<a href="https://matthewspesier.us" className="footer-logo"><img src="" alt=""/></a>
	  	</div>
	  	<div className="footer-about">
	  		<div className="">
	  			<div className="title">Matthew Speiser</div>
	  			<p>Software Engineer, Full Stack Developer, Learner</p>
	  		</div>
	  	</div>
	  	<div className="footer-social">
	  	  <ul className="social">
          <li>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/_matthewspeiser">
              <TwitterIcon />
            </a>
          </li>
	  	  	<li>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/thespeiser">
              <FacebookIcon />
            </a>
          </li>
	  	  	<li>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/code_speiser">
              <InstagramIcon />
            </a>
          </li>
	  	  	<li>
            <a target="_blank" rel="noreferrer" href="https://www.github.com/mrspeiser">
              <GithubIcon />
            </a>
          </li>
	  	  </ul>	
	  	</div>
	  	<div className="footer-bottom">
	  		<div className="copyrights">2023 &copy; Matthew Speiser</div>
	  	</div>
	  </footer>

  )
}

export default Footer;
