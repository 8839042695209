import * as React from 'react';
import { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Recaptcha from "react-google-recaptcha"
import axios from 'axios';
import geturl from '../utils/urls';
const sitekey = ["6LdvNoEhAAAAAG37VewSLGw6qzByfW6aj-EyxdQ_"]
const baseUrl = geturl('prod');

export default function ContactForm(props){
  
  let [ firstName,setFirstName ] = useState(""); 
  let [ lastName,setLastName ] = useState(""); 
  let [ email,setEmail ] = useState(""); 
  let [ phone,setPhone ] = useState(""); 
  let [ message,setMessage ] = useState(""); 
  let [ error,setError ] = useState(""); 

  const captchaRef = useRef(null);
  const formRef = useRef(null);

  const verifyLessThan = (num) => (value) => (property) => {
    if(value.length < num){
      return true;
    }
    throw new Error(`${property} less is not than ${num} characters`);
  }

  const verifyMoreThan = (num) => (property) => (value) => {
    if(value.length > num){
      return true;
    }
    throw new Error(`${property} is not more than ${num} characters`);
  }

  const verifyMessage = (message) => {
    try {
      const lessThan10000 = verifyLessThan(1000);
      const lessThan20 = verifyLessThan(20);
      const lessThan30 = verifyLessThan(30);
      const moreThan0 = verifyMoreThan(0);

      const firstNameTest = [moreThan0,lessThan30].map(fn => fn('first name'))
      const lastNameTest = [moreThan0,lessThan30].map(fn => fn('last name'));
      const emailTest = [moreThan0,lessThan30].map(fn => fn('email'));
      const phoneTest = [lessThan20].map(fn => fn('phone'));
      const messageTest = [moreThan0,lessThan10000].map(fn => fn('message'));

      const tests = {
        firstName:firstNameTest,
        lastName:lastNameTest,
        email:emailTest,
        phone:phoneTest,
        message:messageTest
      }
      
      for(const key in message){
        tests[key].map(fn => fn(message[key]));
      }

      return [{...message},null]
    } catch(err) {
      return [null,err.message]
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("")
    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      message: message
    }
    const [verified,err] = verifyMessage(body)
    if(verified !== null){
      const token = captchaRef.current.getValue();
      verified.captcha = token;
    }
    if(err){
      setError(err)
    }
    if(!err){
      axios.post(`${baseUrl}/message`, verified)
      .then((response) => {
        formRef.current.reset();
        captchaRef.current.reset();
      })
      .catch((error) => {
        setError(error.response.data.message);
        captchaRef.current.reset();
      });
    }
  }

  return (
    <div className="Contact-Form-Wrapper">
      <form ref={formRef} onSubmit={handleSubmit}>
      <Grid
        container
        spacing={1}>
        <Grid xs={12} sm={6} item>
          <TextField 
            required
            id="outlined" 
            label="First Name"
            fullWidth
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <TextField 
            required 
            id="outlined" 
            label="Last Name"
            fullWidth
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <TextField 
            required 
            id="outlined" 
            label="Email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <TextField 
            id="outlined" 
            label="Phone"
            fullWidth
            onChange={(e) => setPhone(e.target.value)}
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            required
            id="outlined-multiline-static"
            label="Message"
            multiline
            rows={9}
            defaultValue=""
            fullWidth
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
        <Grid xs={12} item>
          <Recaptcha 
            sitekey={sitekey[0]}
            ref={captchaRef}
          />
        </Grid>
        <Grid xs={12} item>
          <Button 
            variant="contained" 
            color="primary"
            type="submit"
            fullWidth
          >Submit
          </Button>
        </Grid>
        <Grid xs={12} item>
          {error.length > 0 ? <h5 className="error">{error}</h5> : ""}
        </Grid>
      </Grid>
      </form>
    </div>
  )
};
