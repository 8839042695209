import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import About from './views/About';
import Blogs from './views/Blogs';
import Blog from './views/Blog';
import BlogCategory from './views/BlogCategory';
import Projects from './views/Projects';
import Project from './views/Project';
import Contact from './views/Contact';
import Message from './views/Message';
import Appointment from './views/Appointment';
import ErrorPage from './views/ErrorPage';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

window.versionNumber = 4;
//fetch(url)
//  .then(res => res.json())
//  .then(data => console.log(data))
//  .catch(err => console.log(err))
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}/>
      <Route path="/about" element={<About />}/>

      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:category" element={<BlogCategory />}/>
      <Route path="/blogs/:category/:blog" element={<Blog />}/>

      <Route path="/projects" element={<Projects />} /> 
      <Route path="/projects/:project" element={<Project />} />

      <Route path="/contact" element={<Contact />} />
      <Route path="/contact/message" element={<Message />}/>
      <Route path="/contact/appointment" element={<Appointment />}/>
      <Route path="*" element={<ErrorPage />} />

    </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
